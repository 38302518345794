<template>
  <!--    Bank Form-->
  <validation-observer
    ref="bankForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updateBankMode?'Update':'Create'} Bank`"
      :show="bankModalOpened"
      :show-overlay="deletingBank"
      @onClose="closeBankModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedBankId"
            placeholder="All Banks"
            :options="walletBanks"
            label-name="title"
            value-name="id"
            @input="populateBankForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteBank()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @onClick="resetBankForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <lenon-input
        v-model="bank.title"
        name="title"
        placeholder="eg. My Fidelity Bank"
        rules="required"
        label="Title"
      />
      <lenon-select
        v-model="bank.bank_code"
        name="bank_name"
        rules="required"
        label="Bank/MoMo"
        label-name="name"
        value-name="code"
        placeholder="Select Bank or MoMo"
        :options="banks"
      />
      <lenon-input
        v-model="bank.account_name"
        name="account_name"
        rules="required"
        label="Account Holder Name"
        placeholder="Account Holder Name"
      />
      <lenon-input
        v-model="bank.account_number"
        name="account_number"
        type="number"
        rules="required|max_length:16"
        label="Bank Account/MoMo Number"
        placeholder="Bank Account/MoMo Number"
      />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeBankModal()"
          />
          <lenon-button
            :label="updateBankMode?'Update':'Create'"
            :disabled="invalid"
            :loading="bankLoading"
            loading-text="Loading..."
            @onClick="updateBankMode?updateBank():createBank()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import { BRow, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import { CREATE_BANK_M, DELETE_BANK_M, UPDATE_BANK_M } from '@/graphql/mutations'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay'
import logData from '@/libs/log'

export default {
  name: 'BankSetup',
  components: {
    ErrorDisplay,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast],
  props: {
    modalOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      error: {},
      bankModalOpened: false,
      updateBankMode: false,
      bankLoading: false,
      deletingBank: false,
      selectedBankId: null,
      bank: {
        id: null,
        title: null,
        bank_code: null,
        account_name: null,
        account_number: null,
      },
    }
  },
  computed: {
    selectedBank() {
      return this.walletBanks.find(ter => ter.id === this.selectedBankId)
    },
    walletBanks() {
      return this.$store.getters['staff/walletBanks']
    },
    banks() {
      return this.$store.getters['staff/banks']
    },
  },
  watch: {
    modalOpened(opened) {
      this.bankModalOpened = opened
    },
  },
  methods: {
    // bank
    resetBankForm() {
      this.selectedBankId = null
      this.updateBankMode = false
      this.bank = {
        id: null,
        title: null,
        bank_code: null,
        account_name: null,
        account_number: null,
      }
      this.hasNoItems = false
      this.$refs.bankForm.reset()
    },
    populateBankForm(bank) {
      if (this.selectedBankId) {
        this.updateBankMode = true
        this.bank = { ...this.selectedBank }
      } else {
        this.resetBankForm()
      }
    },
    closeBankModal() {
      this.bankModalOpened = false
      this.$emit('modalClosed')
    },
    updateBank() {
      this.error = {}
      if (!this.selectedBankId) {
        return
      }
      this.bankLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_BANK_M,
        variables: { input: this.bank },
      }).then(res => {
        this.showSuccess('Updated bank successfully')
        this.bankLoading = false
        this.$store.commit('staff/updateBank', res.data.updateBank)
      }).catch(err => {
        logData(err)
        this.error = err
        this.showError('Failed to update bank')
        this.bankLoading = false
      })
    },
    createBank() {
      this.error = {}
      this.bankLoading = true
      this.$apollo.mutate({
        mutation: CREATE_BANK_M,
        variables: { input: this.bank },
      }).then(res => {
        this.showSuccess('Created bank successfully')
        this.bankLoading = false
        this.$store.commit('staff/addBank', res.data.createBank)
        this.resetBankForm()
      }).catch(err => {
        logData(err)
        this.error = err
        this.bankLoading = false
        this.showError('Failed to create bank')
      })
    },
    deleteBank() {
      if (!this.selectedBankId) {
        this.showInfo('Please select a bank')
        return
      }
      this.deletingBank = true
      this.$apollo.mutate({ mutation: DELETE_BANK_M, variables: { id: this.selectedBankId } }).then(() => {
        this.deletingBank = false
        this.showSuccess('Deleted Successfully')
        this.$store.commit('staff/removeBank', this.selectedBankId)
        this.selectedBankId = null
        this.resetBankForm()
      }).catch(() => {
        this.showError('Failed to delete bank')
        this.deletingBank = false
      })
    },

  },
}
</script>
