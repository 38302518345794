<template>
  <!--    Staff Form-->
  <validation-observer
    ref="staffForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updateStaffMode?'Update':'Create'} Staff Member`"
      :show="staffModalOpened"
      :show-overlay="deletingStaff"
      size="md"
      @onClose="closeStaffModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedStaffId"
            placeholder="All Staff Members"
            :options="staffs"
            label-name="name"
            value-name="id"
            name="staffs"
            @input="populateStaffForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
            name="delete_staff"
          >
            <b-dropdown-item
              id="cd_staff"
              @click="deleteStaff()"
            >
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
            name="staff_action"
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @onClick="resetStaffForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <div
        v-if="selectedUserPhoto"
        class="d-flex mt-2 mb-1 justify-content-center"
      >
        <lenon-profile-photo
          size="100"
          :photo="selectedUserPhoto"
        />
      </div>
      <div class="row">
        <div class="col-12">
          <app-collapse
            accordion
            type="margin"
          >
            <app-collapse-item
              id="personal-info"
              title="Personal Details"
            >
              <div class="row align-items-center">
                <div class="col-9">
                  <lenon-select
                    v-model="staff.user_id"
                    name="user"
                    label="User"
                    placeholder="Select User"
                    rules="required"
                    label-name="name"
                    value-name="id"
                    :options="users"
                  />
                </div>
                <div class="col-3 mt-1">
                  <lenon-button
                    variant="flat-primary"
                    :icon-only="true"
                    icon="UserPlusIcon"
                    tool-tip-text="Click to open user accounts management"
                    @onClick="$router.push({name:'account-users'})"
                  />
                </div>
              </div>
              <lenon-select
                v-model="staff.gender"
                name="gender"
                label="Gender"
                placeholder="Select Gender"
                rules="required"
                :options="genders"
              />
              <lenon-date-picker
                v-model="staff.dob"
                name="dob"
                rules="required"
                placeholder="Date of Birth"
                label="Date of Birth"
              />
              <lenon-input
                v-model="staff.address"
                name="school_email"
                rules="required"
                label="Staff Address"
                placeholder="Staff Address"
              />
              <lenon-input
                v-model="staff.emergency_contact_name"
                label="Emergency Contact Name"
                name="emergency_contact_name"
                placeholder="Full Name"
              />
              <lenon-input
                v-model="staff.emergency_contact_phone"
                name="emergency_contact_phone"
                rules="max_length:15"
                label="Emergency Contact Phone"
                placeholder="Phone"
              />
            </app-collapse-item>
            <app-collapse-item
              id="employment-details"
              title="Employment Details"
            >
              <lenon-select
                v-model="staff.position_id"
                name="position"
                label="Position"
                rules="required"
                label-name="title"
                value-name="id"
                :options="positions"
              />

              <lenon-select
                v-model="staff.department_id"
                name="department"
                label="Department"
                rules="required"
                label-name="title"
                value-name="id"
                :options="departments"
              />
              <lenon-select
                v-model="staff.employment_type"
                name="employment_type"
                label="Employment Types"
                rules="required"
                :options="employment_types"
              />
              <lenon-input
                v-model="staff.base_salary"
                name="base_salary"
                type="number"
                rules="required"
                label="Base Salary/Month"
                placeholder="Base Salary"
              />
              <lenon-date-picker
                v-model="staff.employment_start_date"
                name="employment_start_date"
                label="Employment Start Date"
                placeholder="Employment Start Date"
                rules="required"
              />
              <b-form-group v-if="updateStaffMode">
                <b-form-checkbox
                  v-model="employmentEnded"
                >
                  End Employment
                </b-form-checkbox>
              </b-form-group>
              <lenon-date-picker
                v-if="employmentEnded"
                v-model="staff.employment_end_date"
                name="employment_end_date"
                label="Employment End Date"
                placeholder="Employment End Date"
                rules="required"
              />
              <lenon-text-area
                v-if="employmentEnded"
                v-model="staff.employment_end_reason"
                name="employment_end_reason"
                label="Reason"
                placeholder="Enter Reason"
              />
            </app-collapse-item>
            <app-collapse-item
              id="financial-info"
              title="Financial Details"
            >
              <lenon-select
                v-model="staff.bank_name"
                name="bank_name"
                rules="required"
                label="Bank/MoMo"
                label-name="name"
                value-name="name"
                placeholder="Select Bank or MoMo"
                :options="banks"
              />
              <lenon-input
                v-model="staff.bank_account_name"
                name="bank_account_name"
                rules="required"
                label="Account Holder Name"
                placeholder="Account Holder Name"
              />
              <lenon-input
                v-model="staff.bank_account_number"
                name="bank_account_number"
                type="number"
                rules="required|max_length:16"
                label="Bank Account/MoMo Number"
                placeholder="Bank Account/MoMo Number"
              />

              <b-form-group>
                <b-form-checkbox
                  v-model="staff.pays_tax"
                >
                  PAYE Tax
                </b-form-checkbox>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  v-model="staff.pays_ssnit"
                >
                  SSNIT Contribution
                </b-form-checkbox>
              </b-form-group>
              <lenon-input
                v-if="staff.pays_ssnit"
                v-model="staff.ssnit"
                name="ssnit"
                label="SSNIT Number"
                placeholder="SSNIT Number"
                rules="required"
              />
            </app-collapse-item>
            <!--            <app-collapse-item-->
            <!--                id="benefits-deductions"-->
            <!--                title="Benefits & Deductions"-->
            <!--            >-->

            <!--            </app-collapse-item>-->
          </app-collapse>
        </div>
      </div>

      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            name="cancel"
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeStaffModal()"
          />
          <lenon-button
            name="save_staff"
            :label="updateStaffMode?'Update':'Create'"
            :disabled="invalid"
            :loading="staffLoading"
            loading-text="Loading..."
            @onClick="updateStaffMode?updateStaff():createStaff()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BDropdownItem, BDropdownDivider, BFormCheckbox, BFormGroup,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import {
  CREATE_STAFF_M,
  DELETE_STAFF_M,
  UPDATE_STAFF_M,
} from '@/graphql/mutations'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect.vue'
import logData from '@/libs/log'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import LenonDatePicker from '@/lenon/components/LenonDatePicker'
import LenonProfilePhoto from '@/lenon/components/LenonProfilePhoto'
import LenonTextArea from '@/lenon/components/LenonTextArea'

export default {
  name: 'StaffMemberSetup',
  components: {
    LenonTextArea,
    LenonProfilePhoto,
    LenonDatePicker,
    ErrorDisplay,
    LenonMultiSelect,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
    BFormCheckbox,
    BFormGroup,
    AppCollapseItem,
    AppCollapse,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
      staffModalOpened: false,
      updateStaffMode: false,
      staffLoading: false,
      deletingStaff: false,
      employmentEnded: false,
      selectedStaffId: null,
      staff: {
        id: null,
        user_id: null,
        gender: null,
        dob: null,
        emergency_contact_name: null,
        emergency_contact_phone: null,
        address: null,
        position_id: null,
        department_id: null,
        employment_type: null,
        employment_start_date: null,
        employment_end_date: null,
        employment_end_reason: null,
        bank_name: null,
        bank_code: null,
        bank_account_name: null,
        bank_account_number: null,
        base_salary: null,
        ssnit: null,
        pays_ssnit: false,
        pays_tax: false,
      },
      genders: ['Male', 'Female'],
      employment_types: [
        'Full Time',
        'Part Time',
      ],
    }
  },
  computed: {
    selectedUserPhoto() {
      const user = this.users.find(u => u.id === this.staff.user_id)
      if (user && user.photo) {
        return user.photo
      }
      return null
    },
    selectedStaff() {
      const staff = this.staffs.find(ter => ter.id === this.selectedStaffId)
      if (!staff) {
        return null
      }
      return staff
    },
    positions() {
      return this.$store.getters['staff/positions']
    },
    staffs() {
      return this.$store.getters['staff/members']
        .map(u => ({
          ...u,
          name: `${u.first_name} ${u.last_name}`,
        }))
    },
    departments() {
      return this.$store.getters['staff/departments']
    },
    banks() {
      return this.$store.getters['staff/banks']
    },
    users() {
      return this.$store.getters['accountUsers/users'].map(u => ({
        ...u,
        name: `${u.first_name} ${u.last_name}`,
      }))
    },
    bankName() {
      return this.staff.bank_name
    },
    userId() {
      return this.staff.user_id
    },
  },
  watch: {
    modalOpened(opened) {
      this.staffModalOpened = opened
    },
    bankName(name) {
      if (name) {
        const b = this.banks.find(b => b.name === name)
        this.staff.bank_code = b.code
      }
    },
    userId(id) {
      if (id) {
        const staff = this.staffs.find(s => s.user_id === id)
        if (staff) {
          this.selectedStaffId = staff.id
        } else {
          this.selectedStaffId = null
        }
      }
    },
  },
  methods: {
    // staff
    resetStaffForm() {
      this.staff = {
        id: null,
        user_id: null,
        gender: null,
        dob: null,
        emergency_contact_name: null,
        emergency_contact_phone: null,
        address: null,
        position_id: null,
        department_id: null,
        employment_type: null,
        employment_start_date: null,
        employment_end_date: null,
        employment_end_reason: null,
        bank_name: null,
        bank_code: null,
        bank_account_name: null,
        bank_account_number: null,
        base_salary: null,
        ssnit: null,
        pays_ssnit: false,
        pays_tax: false,
      }
      this.selectedStaffId = null
      this.updateStaffMode = false
      this.employmentEnded = false
      this.$refs.staffForm.reset()
    },
    populateStaffForm(staff) {
      if (this.selectedStaff) {
        this.employmentEnded = !!this.selectedStaff.employment_end_date
        this.updateStaffMode = true
        this.staff = {
          ...this
            .selectedStaff,
        }
        // eslint-disable-next-line no-underscore-dangle
        delete this.staff.__typename
        delete this.staff.name
        delete this.staff.staff_id
        delete this.staff.first_name
        delete this.staff.last_name
        delete this.staff.photo
        delete this.staff.phone
        delete this.staff.email
        delete this.staff.status
        delete this.staff.position
        delete this.staff.department
        delete this.staff.age
      } else {
        this.resetStaffForm()
      }
    },
    closeStaffModal() {
      this.staffModalOpened = false
      this.$emit('modalClosed')
    },
    updateStaff() {
      this.error = {}
      delete this.staff._showDetails
      if (!this.staff.id) {
        return
      }
      if (!this.employmentEnded) {
        this.staff.employment_end_date = null
        this.staff.employment_end_reason = null
      }
      this.staffLoading = true
      this.staff.base_salary = +this.staff.base_salary
      this.$apollo.mutate({
        mutation: UPDATE_STAFF_M,
        variables: {
          input: this.staff,
        },
      })
        .then(res => {
          this.showSuccess('Updated staff successfully')
          this.staffLoading = false
          this.$store.commit('staff/updateStaff', res.data.updateStaff)
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to update staff')
          this.staffLoading = false
        })
    },
    createStaff() {
      this.error = {}
      this.staffLoading = true
      this.staff.base_salary = +this.staff.base_salary
      this.$apollo.mutate({
        mutation: CREATE_STAFF_M,
        variables: {
          input: this.staff,
        },
      })
        .then(res => {
          this.showSuccess('Created staff successfully')
          this.staffLoading = false
          this.$store.commit('staff/addStaff', res.data.createStaff)
          this.resetStaffForm()
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to create staff')
          this.staffLoading = false
        })
    },
    deleteStaff() {
      if (!this.selectedStaffId) {
        this.showInfo('Please select a staff')
        return
      }
      this.deletingStaff = true
      this.$apollo.mutate({
        mutation: DELETE_STAFF_M,
        variables: { id: this.selectedStaffId },
      })
        .then(() => {
          this.deletingStaff = false
          this.showSuccess('Deleted Successfully')
          this.$store.commit('staff/removeStaff', this.selectedStaffId)
          this.selectedStaffId = null
          this.resetStaffForm()
        })
        .catch(() => {
          this.showError('Failed to delete staff')
          this.deletingStaff = false
        })
    },
  }
  ,
}
</script>
