<template>
  <div class="staff-members">
    <lenon-list-page
        title="School Wallet"
        :columns="columns"
        :rows="transactions"
        :table-loading="tableLoading"
        :show-create="false"
        :show-refresh="false"
        :show-search="true"
        :show-details="true"
        :show-profile-photo="true"
        search-placeholder="Search..."
    >
      <template slot="right-extra-header-actions">
        <div class="d-flex align-self-center align-items-start" style="margin-bottom: -20px">
          <lenon-button
              variant="outline-primary"
              class="mr-2"
              icon="MinusCircleIcon"
              label="Withdraw"
              tool-tip-text="Withdraw money from your school's wallet to a specified bank account"
              @onClick="showWithdrawalModal()"
          />
          <lenon-button
              variant="outline-primary"
              icon="PlusCircleIcon"
              label="Deposit"
              tool-tip-text="Deposit money into your school's wallet for future disbursement"
              class="mr-2"
              @onClick="showDepositModal()"
          />
          <lenon-button
              icon="SettingsIcon"
              label="Bank Accounts"
              tool-tip-text="Add bank accounts and later use as destination accounts when withdrawing money from your wallet."
              @onClick="showLinkBankModal()"
          />
        </div>
      </template>
      <template slot="table-header">
        <div class="d-flex flex-row mb-1 ml-1">
          <div class="">
            <strong>Current Balance:
              <b-badge variant="light-success" style="font-size: 15px;">
                <small>{{ $store.getters['auth/currency'] }}</small>+{{ currentBalance | currency }}
              </b-badge>
            </strong>
          </div>
        </div>
      </template>
      <template #name="{row}">
        {{ row.item.first_name }} {{ row.item.last_name }}
      </template>
      <template #status="{row}">
        <b-badge :variant="row.item.status==='Active'?'light-success':'light-danger'">
          <strong>{{ row.item.status }}</strong>
        </b-badge>
      </template>
      <template #actions="{row}">
        <lenon-button
            icon-only
            variant="flat-primary"
            :icon="row.detailsShowing?'EyeOffIcon':'EyeIcon'"
            tool-tip-text="View staff details"
            @onClick="row.toggleDetails"
        />
      </template>
    </lenon-list-page>
    <lenon-modal
        :show="withdrawalModalOpened"
        :show-overlay="withdrawing"
        @onClose="closeWithdrawalModal()"
        title="Withdraw from Wallet"
        ok-text="Withdraw"
        :ok-disabled="!withdrawalAmount || !narration"
    >
      <div class="row mb-1">
        <div class="col-12">
          <strong>Current Balance: </strong> <span><b-badge variant="light-success">GHS+{{currentBalance | currency}}</b-badge></span>
        </div>
      </div>
      <lenon-select
          v-model="bankId"
          placeholder="Destination Account"
          :options="walletBanks"
          label-name="title"
          value-name="id"
      />
      <div v-if="selectedBank" class="row">
        <div class="col-12">
          <strong>Account: </strong> <span><b-badge variant="light-success">{{selectedBank.account_number}}</b-badge></span>
        </div>
      </div>
      <lenon-input placeholder="Enter Amount" v-model="withdrawalAmount"/>
      <lenon-text-area placeholder="Enter Narration/ Reason" v-model="narration"/>
    </lenon-modal>
    <lenon-modal
        :show="depositModalOpened"
        :show-overlay="depositing"
        @onClose="closeDepositModal()"
        title="Deposit to Wallet"
        ok-text="Deposit"
        :ok-disabled="!depositAmount"
    >
      <div class="row">
        <div class="col-12">
          <strong>Current Balance: </strong> <span><b-badge variant="light-success">GHS+{{currentBalance | currency}}</b-badge></span>
        </div>
      </div>
      <lenon-input placeholder="Enter Amount" v-model="depositAmount"/>
    </lenon-modal>
<!--    <validation-observer-->
<!--        ref="linkForm"-->
<!--        v-slot="{invalid}"-->
<!--    >-->
<!--    <lenon-modal-->
<!--        :show="linkBankModalOpened"-->
<!--        :show-overlay="linking"-->
<!--        @onClose="closeLinkBankModal()"-->
<!--        title="Link Bank to Wallet"-->
<!--    >-->
<!--    -->
<!--      <template slot="modal-actions">-->
<!--        <b-row class="float-right">-->
<!--          <lenon-button-->
<!--              name="cancel"-->
<!--              variant="flat-danger"-->
<!--              icon="XIcon"-->
<!--              class="mr-1"-->
<!--              label="Cancel"-->
<!--              @onClick="closeLinkBankModal()"-->
<!--          />-->
<!--          <lenon-button-->
<!--              name="link_bank"-->
<!--              label="Link"-->
<!--              :disabled="invalid"-->
<!--              loading-text="Loading..."-->
<!--          />-->
<!--        </b-row>-->
<!--      </template>-->
<!--    </lenon-modal>-->
<!--    </validation-observer>-->
    <bank-setup
        :modal-opened="linkBankModalOpened"
        @modalClosed="closeModal('bank')"
    />
  </div>
</template>

<script>
import {
  BBadge, BCard, BCol, BRow, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import StaffPositionSetup from '@/views/staff/members/StaffPositionSetup.vue'
import StaffMemberSetup from '@/views/staff/members/StaffMemberSetup.vue'
import DepartmentSetup from '@/views/staff/members/DepartmentSetup.vue'
import { TRANSACTIONS_AND_BANKS_Q } from '@/graphql/queries'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonMonthYearPicker from '@/lenon/components/LenonMonthYearPicker'
import LenonModal from '@/lenon/components/LenonModal'
import LenonInput from '@/lenon/components/LenonInput'
import LenonTextArea from '@/lenon/components/LenonTextArea'
import { ValidationObserver } from 'vee-validate'
import LenonSelect from '@/lenon/components/LenonSelect'
import BankSetup from '@/views/staff/wallet/BankSetup'
export default {
  name: 'StaffMemberList',
  components: {
    BankSetup,
    LenonSelect,
    LenonTextArea,
    LenonInput,
    LenonModal,
    LenonMonthYearPicker,
    LenonListPage,
    DepartmentSetup,
    StaffMemberSetup,
    StaffPositionSetup,
    LenonButton,
    BCard,
    BRow,
    BCol,
    BBadge,
    BFormCheckbox,
    ValidationObserver
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [showToast],
  data() {
    return {
      staffPositionModalOpened: false,
      staffMemberModalOpened: false,
      departmentModalOpened: false,
      tableLoading: false,

      currentBalance: 0,
      withdrawalModalOpened: false,
      withdrawing: false,
      withdrawalAmount: null,
      narration: null,
      bankId: null,

      depositModalOpened: false,
      depositing: false,
      depositAmount: null,

      linkBankModalOpened: false,
      linking: false,
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          key: 'name',
          label: 'Transaction By',
        },
        {
          key: 'type',
          label: 'Transaction Type',
        },
        {
          key: 'date',
          label: 'Date',
        },
        {
          key: 'staff_id',
          label: 'Amount',
        },
        {
          key: 'base',
          label: 'Narration',
        },
      ],
    }
  },
  computed: {
    banks(){
      return this.$store.getters['staff/banks']
    },
    transactions(){
      return this.$store.getters['staff/transactions']
    },
    walletBanks(){
      return this.$store.getters['staff/walletBanks']
    },
    selectedBank(){
      return this.walletBanks.find((b)=>b.id===this.bankId)
    }
  },
  mounted() {
    this.fetchTransactionsAndBanks()
  },
  methods: {
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'department':
          this.departmentModalOpened = false
          break
        case 'member':
          this.staffMemberModalOpened = false
          break
        case 'position':
          this.staffPositionModalOpened = false
        case 'bank':
          this.linkBankModalOpened = false
      }
    },
    closeLinkBankModal() {
      this.linkBankModalOpened = false
    },
    showLinkBankModal() {
      this.linkBankModalOpened = true
    },
    closeDepositModal() {
      this.depositModalOpened = false
    },
    showDepositModal() {
      this.depositModalOpened = true
    },
    closeWithdrawalModal() {
      this.withdrawalModalOpened = false
    },
    showWithdrawalModal() {
      this.withdrawalModalOpened = true
    },
    showDepartmentModal() {
      this.departmentModalOpened = true
    },
    showStaffMemberModal() {
      this.staffMemberModalOpened = true
    },
    showStaffPositionModal() {
      this.staffPositionModalOpened = true
    },
    fetchTransactionsAndBanks() {
      this.tableLoading = true
      this.$apollo.query({ query: TRANSACTIONS_AND_BANKS_Q })
          .then(res => {
            this.$store.commit('staff/setTransactionsAndBanks', res.data)
          }).finally(()=>{
        this.tableLoading = false
      })
    },
  },
}
</script>
