<template>
  <!--    Position Form-->
  <validation-observer
    ref="positionForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updatePositionMode?'Update':'Create'} Position`"
      :show="positionModalOpened"
      :show-overlay="deletingPosition"
      @onClose="closePositionModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedPositionId"
            placeholder="All Positions"
            :options="positions"
            label-name="title"
            value-name="id"
            @input="populatePositionForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deletePosition()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @onClick="resetPositionForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <lenon-input
        v-model="title"
        name="name"
        placeholder="Enter position name"
        rules="required"
        label="Position Name"
      />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closePositionModal()"
          />
          <lenon-button
            :label="updatePositionMode?'Update':'Create'"
            :disabled="invalid"
            :loading="positionLoading"
            loading-text="Loading..."
            @onClick="updatePositionMode?updatePosition():createPosition()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import { BRow, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import { CREATE_POSITION_M, DELETE_POSITION_M, UPDATE_POSITION_M } from '@/graphql/mutations'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay'
import logData from '@/libs/log'

export default {
  name: 'PositionSetup',
  components: {
    ErrorDisplay,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast],
  props: {
    modalOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      error: {},
      positionModalOpened: false,
      updatePositionMode: false,
      positionLoading: false,
      deletingPosition: false,
      selectedPositionId: null,
      title: '',
    }
  },
  computed: {
    selectedPosition() {
      return this.positions.find(ter => ter.id === this.selectedPositionId)
    },
    positions() {
      return this.$store.getters['staff/positions']
    },
  },
  watch: {
    modalOpened(opened) {
      this.positionModalOpened = opened
    },
  },
  methods: {
    // position
    resetPositionForm() {
      this.title = ''
      this.selectedPositionId = null
      this.updatePositionMode = false
      this.$refs.positionForm.reset()
    },
    populatePositionForm(position) {
      if (this.selectedPosition) {
        this.updatePositionMode = true
        this.title = this.selectedPosition?.title
      } else {
        this.resetPositionForm()
      }
    },
    closePositionModal() {
      this.positionModalOpened = false
      this.$emit('modalClosed')
    },
    updatePosition() {
      this.error = {}
      if (!this.selectedPositionId) {
        return
      }
      this.positionLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_POSITION_M,
        variables: { input: { id: this.selectedPositionId, title: this.title } },
      }).then(res => {
        this.showSuccess('Updated position successfully')
        this.positionLoading = false
        this.$store.commit('staff/updatePosition', res.data.updatePosition)
      }).catch(err => {
        logData(err)
        this.error = err
        this.showError('Failed to update position')
        this.positionLoading = false
      })
    },
    createPosition() {
      this.error = {}
      this.positionLoading = true
      this.$apollo.mutate({
        mutation: CREATE_POSITION_M,
        variables: { input: { title: this.title } },
      }).then(res => {
        this.showSuccess('Created position successfully')
        this.positionLoading = false
        this.$store.commit('staff/addPosition', res.data.createPosition)
        this.resetPositionForm()
      }).catch(err => {
        logData(err)
        this.error = err
        this.positionLoading = false
        this.showError('Failed to create position')
      })
    },
    deletePosition() {
      if (!this.selectedPositionId) {
        this.showInfo('Please select a position')
        return
      }
      this.deletingPosition = true
      this.$apollo.mutate({ mutation: DELETE_POSITION_M, variables: { id: this.selectedPositionId } }).then(() => {
        this.deletingPosition = false
        this.showSuccess('Deleted Successfully')
        this.$store.commit('staff/removePosition', this.selectedPositionId)
        this.selectedPositionId = null
        this.resetPositionForm()
      }).catch(() => {
        this.showError('Failed to delete position')
        this.deletingPosition = false
      })
    },

  },
}
</script>
