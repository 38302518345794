<template>
  <!--    Department Form-->
  <validation-observer
      ref="departmentForm"
      v-slot="{invalid}"
  >
    <lenon-modal
        :title="`${updateDepartmentMode?'Update':'Create'} Department`"
        :show="departmentModalOpened"
        :show-overlay="deletingDepartment"
        @onClose="closeDepartmentModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
              v-model="selectedDepartmentId"
              placeholder="All Departments"
              :options="departments"
              label-name="title"
              value-name="id"
              @input="populateDepartmentForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
              icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteDepartment()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
              variant="flat-danger"
              icon="XIcon"
              label=""
              tool-tip-text="Clear Form"
              @onClick="resetDepartmentForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <lenon-input
          v-model="title"
          name="name"
          placeholder="Enter department name"
          rules="required"
          label="Department Name"
      />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
              variant="flat-danger"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @onClick="closeDepartmentModal()"
          />
          <lenon-button
              :label="updateDepartmentMode?'Update':'Create'"
              :disabled="invalid"
              :loading="departmentLoading"
              loading-text="Loading..."
              @onClick="updateDepartmentMode?updateDepartment():createDepartment()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import { BRow, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import { CREATE_DEPARTMENT_M, DELETE_DEPARTMENT_M, UPDATE_DEPARTMENT_M } from '@/graphql/mutations'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay'
import logData from '@/libs/log'

export default {
  name: 'DepartmentSetup',
  components: {
    ErrorDisplay,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast],
  props: {
    modalOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      error: {},
      departmentModalOpened: false,
      updateDepartmentMode: false,
      departmentLoading: false,
      deletingDepartment: false,
      selectedDepartmentId: null,
      title: '',
    }
  },
  computed: {
    selectedDepartment() {
      return this.departments.find(ter => ter.id === this.selectedDepartmentId)
    },
    departments() {
      return this.$store.getters['staff/departments']
    },
  },
  watch: {
    modalOpened(opened) {
      this.departmentModalOpened = opened
    },
  },
  methods: {
    // department
    resetDepartmentForm() {
      this.title = ''
      this.selectedDepartmentId = null
      this.updateDepartmentMode = false
      this.$refs.departmentForm.reset()
    },
    populateDepartmentForm(department) {
      if (this.selectedDepartment) {
        this.updateDepartmentMode = true
        this.title = this.selectedDepartment?.title
      } else {
        this.resetDepartmentForm()
      }
    },
    closeDepartmentModal() {
      this.departmentModalOpened = false
      this.$emit('modalClosed')
    },
    updateDepartment() {
      this.error = {}
      if (!this.selectedDepartmentId) {
        return
      }
      this.departmentLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_DEPARTMENT_M,
        variables: { input: { id: this.selectedDepartmentId, title: this.title } },
      }).then(res => {
        this.showSuccess('Updated department successfully')
        this.departmentLoading = false
        this.$store.commit('staff/updateDepartment', res.data.updateDepartment)
      }).catch(err => {
        logData(err)
        this.error = err
        this.showError('Failed to update department')
        this.departmentLoading = false
      })
    },
    createDepartment() {
      this.error = {}
      this.departmentLoading = true
      this.$apollo.mutate({
        mutation: CREATE_DEPARTMENT_M,
        variables: { input: { title: this.title } },
      }).then(res => {
        this.showSuccess('Created department successfully')
        this.departmentLoading = false
        this.$store.commit('staff/addDepartment', res.data.createDepartment)
        this.resetDepartmentForm()
      }).catch(err => {
        logData(err)
        this.error = err
        this.departmentLoading = false
        this.showError('Failed to create department')
      })
    },
    deleteDepartment() {
      if (!this.selectedDepartmentId) {
        this.showInfo('Please select a department')
        return
      }
      this.deletingDepartment = true
      this.$apollo.mutate({ mutation: DELETE_DEPARTMENT_M, variables: { id: this.selectedDepartmentId } }).then(() => {
        this.deletingDepartment = false
        this.showSuccess('Deleted Successfully')
        this.$store.commit('staff/removeDepartment', this.selectedDepartmentId)
        this.selectedDepartmentId = null
        this.resetDepartmentForm()
      }).catch(() => {
        this.showError('Failed to delete department')
        this.deletingDepartment = false
      })
    },

  },
}
</script>
